import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faClipboardList } from "@fortawesome/pro-solid-svg-icons/faClipboardList";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil";
import { faCopy } from "@fortawesome/pro-solid-svg-icons/faCopy";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faPlay } from "@fortawesome/pro-solid-svg-icons/faPlay";
import { faBell } from "@fortawesome/pro-solid-svg-icons/faBell";
import { faBellSlash } from "@fortawesome/pro-solid-svg-icons/faBellSlash";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faPlug as fasPlug } from "@fortawesome/pro-solid-svg-icons/faPlug";
import { faBurn as fasBurn } from "@fortawesome/pro-solid-svg-icons/faBurn";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faCheckSquare as fasCheckSquare } from "@fortawesome/pro-solid-svg-icons/faCheckSquare";
import { faExternalLinkSquare as fasExternalLinkSquare } from "@fortawesome/pro-solid-svg-icons/faExternalLinkSquare";
import { faMinus as fasMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faEquals as fasEquals } from "@fortawesome/pro-solid-svg-icons/faEquals";
import { faDownload as fasDownload } from "@fortawesome/pro-solid-svg-icons/faDownload";
import { faTools as fasTools } from "@fortawesome/pro-solid-svg-icons/faTools";
import { faUser as fasUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faUsers as fasUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faUserCircle as fasUserCircle } from "@fortawesome/pro-solid-svg-icons/faUserCircle";
import { faMobileAlt as fasMobileAlt } from "@fortawesome/pro-solid-svg-icons/faMobileAlt";
import { faSms as fasSms } from "@fortawesome/pro-solid-svg-icons/faSms";
import { faRedo as fasRedo } from "@fortawesome/pro-solid-svg-icons/faRedo";
import { faShield as fasShield } from "@fortawesome/pro-solid-svg-icons/faShield";
import { faShieldCheck as fasShieldCheck } from "@fortawesome/pro-solid-svg-icons/faShieldCheck";
import { faPhoneAlt as fasPhoneAlt } from "@fortawesome/pro-solid-svg-icons/faPhoneAlt";
import { faLock as fasLock } from "@fortawesome/pro-solid-svg-icons/faLock";

import { faArrows } from "@fortawesome/pro-regular-svg-icons/faArrows";
import { faArrowsV } from "@fortawesome/pro-regular-svg-icons/faArrowsV";
import { faBan } from "@fortawesome/pro-regular-svg-icons/faBan";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons/faCalendarAlt";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faCircle } from "@fortawesome/pro-regular-svg-icons/faCircle";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import { faComment } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faComments } from "@fortawesome/pro-regular-svg-icons/faComments";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faExclamation as farExclamation } from "@fortawesome/pro-regular-svg-icons/faExclamation";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons/faExternalLink";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons/faFileAlt";
import { faFileCsv } from "@fortawesome/pro-regular-svg-icons/faFileCsv";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faFileSignature } from "@fortawesome/pro-regular-svg-icons/faFileSignature";
import { faFrown as farFrown } from "@fortawesome/pro-regular-svg-icons/faFrown";
import { faFrownOpen as farFrownOpen } from "@fortawesome/pro-regular-svg-icons/faFrownOpen";
import { faHandshake as farHandshake } from "@fortawesome/pro-regular-svg-icons/faHandshake";
import { faHardHat as farHardHat } from "@fortawesome/pro-regular-svg-icons/faHardHat";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { faHouseUser } from "@fortawesome/pro-regular-svg-icons/faHouseUser";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";
import { faLaugh as farLaugh } from "@fortawesome/pro-regular-svg-icons/faLaugh";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { faMeh as farMeh } from "@fortawesome/pro-regular-svg-icons/faMeh";
import { faPhoneAlt } from "@fortawesome/pro-regular-svg-icons/faPhoneAlt";
import { faPrint } from "@fortawesome/pro-regular-svg-icons/faPrint";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faShield as farShield } from "@fortawesome/pro-regular-svg-icons/faShield";
import { faShieldAlt as farShieldAlt } from "@fortawesome/pro-regular-svg-icons/faShieldAlt";
import { faSmile as farSmile } from "@fortawesome/pro-regular-svg-icons/faSmile";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { faStar as farStar } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faSync } from "@fortawesome/pro-regular-svg-icons/faSync";
import { faTag } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faThList } from "@fortawesome/pro-regular-svg-icons/faThList";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";
import { faUniversity as farUniversity } from "@fortawesome/pro-regular-svg-icons/faUniversity";
import { faUpload } from "@fortawesome/pro-regular-svg-icons/faUpload";
import { faUser as farUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faUserCircle as farUserCircle } from "@fortawesome/pro-regular-svg-icons/faUserCircle";
import { faUserLock as farUserLock } from "@fortawesome/pro-regular-svg-icons/faUserLock";
import { faUserShield as farUserShield } from "@fortawesome/pro-regular-svg-icons/faUserShield";
import { faWrench as farWrench } from "@fortawesome/pro-regular-svg-icons/faWrench";

import { faLightbulbDollar } from "@fortawesome/pro-light-svg-icons/faLightbulbDollar";
import { faFileInvoiceDollar } from "@fortawesome/pro-light-svg-icons/faFileInvoiceDollar";

library.add(
  faBan,
  faCheck,
  faClipboardList,
  faComment,
  faComments,
  faChevronLeft,
  faChevronRight,
  faDownload,
  fasDownload,
  faExternalLink,
  faExclamationTriangle,
  faInfoCircle,
  faCopy,
  faPencil,
  faPlus,
  faTrash,
  faHistory,
  faEye,
  faEyeSlash,
  faImage,
  faPlay,
  faSync,
  faBell,
  faBellSlash,
  farStar,
  farHandshake,
  farHardHat,
  farUniversity,
  fasStar,
  faTimes,
  fasCheckCircle,
  fasExternalLinkSquare,
  faArrows,
  faArrowsV,
  faPhoneAlt,
  faCalendarAlt,
  faCircle,
  faCheckCircle,
  faTimesCircle,
  faFileCsv,
  faPrint,
  faEnvelope,
  faLink,
  faFilePdf,
  faFileAlt,
  faTag,
  faCog,
  faSquare,
  fasCheckSquare,
  faSearch,
  faThList,
  fasMinus,
  fasEquals,
  faLightbulbDollar,
  faFileSignature,
  fasRedo,
  fasPlug,
  fasBurn,
  fasTools,
  fasPhoneAlt,
  fasUser,
  fasLock,
  fasUsers,
  fasUserCircle,
  fasMobileAlt,
  fasSms,
  fasShield,
  farWrench,
  farExclamation,
  fasShieldCheck,
  farUserCircle,
  faHouseUser,
  farShield,
  farShieldAlt,
  faUpload,
  farUserLock,
  farUserShield,
  farUser,
  farLaugh,
  farSmile,
  farMeh,
  farFrown,
  farFrownOpen,
  faFileInvoiceDollar
);
dom.watch();
